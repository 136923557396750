import { requestPrint } from '@/libs/bixolon/bxlcommon'
import { cutPaper, getPosData, printQRCode } from '@/libs/bixolon/bxlpos'
import { formatCurrency, formatCurrencyCustom, getPosNote, getCummulatedDiscount } from '@/libs/bixolon/helpers'
import { printLine, printMultiple, printItems, printSeparator, printTotals } from '@/libs/bixolon/printer'
import EventBus from '@/plugins/event-bus'
import { df } from '@/plugins/firebase'
import state from '@/state'
import { printerErrors } from '@/libs/jsPrintManager/utils'
import * as JSPM from 'jsprintmanager'

export const reprint = async (printer, fullReceipt, mode) => {
  const printerType = printer?.library
  // Fix za staru konfiguraciju
  if (!['UNIVERSAL', 'NONE'].includes(printerType)) {
    if (!printer.font) printer.font = 0
    if (!printer.size) printer.size = 0
    if (!printer.width) printer.width = '80'
    if (!printer.line_length) printer.line_length = 48
    if (!printer.library) printer.library = 'BIXOLON'
  }

  // let totalDiscount = 0

  let [escpos, doc, printerConfiguration] = [null, null, null]

  const cashRegisterResponse = await df.doc(`cash_registers/${state.getCashRegister().id}`).get()
  const cashRegister = cashRegisterResponse.data()
  const currencySymbol = printer.euro_symbol_type && printer.euro_symbol_type != null ? printer.euro_symbol_type : 'EUR'

  printerConfiguration = printer

  if (printerType === 'UNIVERSAL') {
    escpos = window.Neodynamic.JSESCPOSBuilder
    doc = new escpos.Document()
  }

  const currency = 'EUR'

  const posNote = await getPosNote()

  const company = state.getCurrentCompany()
  const pointOfSale = state.getPointOfSale()

  // const conversionRate = 7.53450
  // const conversionRateString = '7.53450'
  const paymentMethod = fullReceipt.payment_method

  const companyAddress = `${company.address}, ${company.zip_code} ${company.city}`
  const pointOfSaleAddress = `${pointOfSale.address}, ${pointOfSale.zip_code} ${pointOfSale.city}`

  const total = fullReceipt.total / 100
  const fullTotal = fullReceipt.full_total / 100
  const totalWithDiscount = fullReceipt.totalWithDiscount ? fullReceipt.totalWithDiscount : total

  const [zki, jir] = [fullReceipt.zki, fullReceipt.jir]

  const options = {
    horizontal: 0,
    vertical: 0,
    bold: false,
    invert: false,
    underline: false,
    alignment: 0,
    font: printerConfiguration.font,
    printerConfiguration: printerConfiguration,
    document: doc,
    escpos: escpos
  }

  if (state.getPrinterLib === 'UNIVERSAL') {
    options.font = printerConfiguration.font
  } else {
    if (printerConfiguration.font === 'A') options.font = 0
    else if (printerConfiguration.font === 'B') options.font = 1
    else if (printerConfiguration.font === 'C') options.font = 2
  }

  function getOptions (option) {
    const modifiedOptions = JSON.parse(JSON.stringify(options))
    Object.keys(option).forEach(key => {
      modifiedOptions[key] = option[key]
    })
    modifiedOptions.document = options.document
    modifiedOptions.escpos = options.escpos
    modifiedOptions.printerConfiguration = options.printerConfiguration
    return modifiedOptions
  }
  // Begin print
  var date = new Date(fullReceipt.issue_date_time * 1000)

  printLine(company.name, getOptions({ alignment: 1 }))
  printLine(companyAddress + ', ' + company.country, getOptions({ alignment: 1 }))
  printLine(pointOfSale.name, getOptions({ alignment: 1 }))
  printLine(pointOfSaleAddress, getOptions({ alignment: 1 }))
  printLine(`OIB: ${company.oib}`, getOptions({ alignment: 1 }))
  if (mode === 0) { printLine('*** REPRINT ***', getOptions({ bold: true, alignment: 1, horizontal: 1, vertical: 1 })) }
  printSeparator(options)

  let rcptSeparator = '-'
  if (fullReceipt.company_details.rcpt_separator !== undefined && fullReceipt.company_details.rcpt_separator !== '') {
    rcptSeparator = fullReceipt.company_details.rcpt_separator
  }

  if (fullReceipt.designation) {
    fullReceipt.receiptNumber = `${fullReceipt.designation.number}${rcptSeparator}${fullReceipt.designation.location_id}${rcptSeparator}${fullReceipt.designation.register_number}`
  }

  if (fullReceipt.company_details.rcpt_num_year === true) {
    var d = new Date(fullReceipt.issue_date_time * 1000)

    fullReceipt.receiptNumber += `  ${d.getFullYear()} `
  }

  printTotals('Račun br:', `${fullReceipt.receiptNumber}`, options)
  printTotals('Način plaćanja:', ` ${paymentMethod}`, options)

  const operater = fullReceipt.seller_id && fullReceipt.seller_id !== '' ? fullReceipt.seller_id : fullReceipt.seller_name
  if (cashRegister) {
    printTotals(`Operater: ${operater}`, 'Blagajna: ' + cashRegister.number, options)
  } else {
    printTotals(`Operater: ${operater}`, 'Blagajna: 1', options)
  }
  printTotals('Datum: ' + ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear(), 'Vrijeme: ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2), options)
  printSeparator(options)

  const type = fullReceipt.type
  if (type.payer_oib) {
    const customerAddress = `${type.payer_address}, ${type.payer_zip_code} ${type.payer_city}`

    printLine('Kupac', options)
    printLine(`Naziv: ${type.payer_name.toUpperCase()}`, options)
    printLine(`Adresa: ${customerAddress.toUpperCase()}`, options)
    printLine(`Oib: ${type.payer_oib.toUpperCase()}`, options)

    printSeparator(options)
  }

  printMultiple([
    'Artikl',
    'Cijena',
    'Kol',
    'Ukupno'
  ], 0, 3, options)
  // }

  printSeparator(options)

  if (mode === 0 || mode === 2) {
    fullReceipt.items.forEach(item => {
      if (item.discount) {
        printItems([
          `${item.name.toUpperCase()}`.substring(0, printerConfiguration.line_length),
          formatCurrency(currency, item.single_item_price / 100),
          formatCurrency(currency, item.amt / 1000),
          formatCurrency(currency, item.single_item_price / 100 * (item.amt / 1000))
        ], 0, 3, options)
        printItems([
          'Popust:',
          '',
          item.discount?.type === 'RATE' && item.discount?.rate
            ? formatCurrency(currency, (item.discount.rate || 0) / 100) + '%'
            : '',
          formatCurrency(currency, (item.single_item_price / 100 * (item.amt / 1000)) - item.price / 100)
        ], 0, 3, options)
      } else {
        printItems([
          `${item.name.toUpperCase()
            }`.substring(0, printerConfiguration.line_length),
          formatCurrency(currency, item.single_item_price / 100),
          formatCurrency(currency, item.amt / 1000),
          formatCurrency(currency, item.price / 100)
        ], 0, 3, options)
      }
    })
  } else {
    if (mode === 1) {
      printItems([
        'Konzumacija jela i pića',
        formatCurrency(currency, fullReceipt.total / 100),
        formatCurrency(currency, 1),
        formatCurrency(currency, fullReceipt.total / 100)
      ], 0, 5, options)
    }
  }

  printSeparator(options)

  printMultiple([
    'Vrsta',
    'Stopa',
    'Osnovica',
    'Ukupno'
  ], 5, 0, options)

  fullReceipt.pdv.forEach((item) => {
    switch (item.rate) {
      case 0:
        printMultiple([
          'PDV 0%',
          '0%',
          formatCurrency(currency, item.base / 100),
          formatCurrency(currency, 0)
        ], 5, 0, options)
        break

      default:
        printMultiple([
          'PDV ' + `${item.rate / 100} % `,
          `${item.rate / 100} % `,
          formatCurrency(currency, item.base / 100),
          formatCurrency(currency, item.total / 100)
        ], 5, 0, options)
    }
  })
  if (fullReceipt.pnp && fullReceipt.pnp !== null) {
    var pnp = fullReceipt.pnp
    printMultiple([
      'PNP ' + `${pnp.rate / 100} % `,
      `${pnp.rate / 100} % `,
      formatCurrency(currency, pnp.base / 100),
      formatCurrency(currency, pnp.total / 100)
    ], 5, 0, options)
  }

  printSeparator(options)

  const cummulatedDiscount = getCummulatedDiscount(fullReceipt)

  if (cummulatedDiscount > 0 && fullReceipt.discount?.amount > 0) {
    printTotals(
      'Popust na iznos',
      formatCurrencyCustom(currencySymbol, (fullReceipt.discount.amount || 0) / 100),
      options
    )
  } else {
    if (cummulatedDiscount > 0 && fullReceipt.discount.amount > 0) {
      printTotals('Popust na iznos', formatCurrencyCustom(currencySymbol, fullReceipt.discount.amount / 100), options)
    }
  }
  if (cummulatedDiscount > 0) {
    printTotals('Cij. bez pop.', formatCurrencyCustom(currencySymbol, fullTotal), options)
    printTotals('Ukupni popust', formatCurrencyCustom(currencySymbol, cummulatedDiscount), options)
  }
  printTotals('Ukupno', formatCurrencyCustom(currencySymbol, total), getOptions({ bold: false, vertical: 0, horizontal: 1 }))
  // if (company.print_eur === undefined || company.print_eur === null || company.print_eur === true) {
  // var exchangeCurrency = ''
  // var conversionOperation = ''
  // switch (currency) {
  //   case 'HRK':
  //     exchangeCurrency = 'EUR'
  //     conversionOperation = 'divide'
  //     break
  //   case 'EUR':
  //     exchangeCurrency = 'HRK'
  //     conversionOperation = 'multiply'
  //     break
  // }

  // var exchangeAmount = 0
  // switch (conversionOperation) {
  //   case 'multiply':
  //     exchangeAmount = total * conversionRate
  //     break
  //   case 'divide':
  //     exchangeAmount = total / conversionRate
  //     break
  // }

  // printTotals('', formatCurrencyFull(exchangeCurrency, exchangeAmount), getOptions({ bold: false, vertical: 0, horizontal: 1 })) // printLine('\n'.repeat(1), options)
  // printTotals('Tečaj', `1 ${currencySymbol} = ${conversionRateString}`, options)
  // }
  printSeparator(options)
  if (zki || jir) {
    generateQrCode(zki, jir, totalWithDiscount, options, date)
  }
  printLine('\n'.repeat(1), options)
  if (zki && zki !== '') {
    printLine(`ZKI: ${zki}`, getOptions({ alignment: 0 }))
  }
  if (jir && jir !== '') {
    printLine(`JIR: ${jir}`, getOptions({ alignment: 0 }))
  }

  printSeparator(options)
  printLine(posNote, getOptions({ alignment: 1 }))
  printLine('\n'.repeat(3), options)

  if (printerType === 'UNIVERSAL') {
    doc.feed(1).cut()
    var escposCommands = options.document.generateUInt8Array()

    // create ClientPrintJob
    var cpj = new JSPM.ClientPrintJob()

    // Set Printer info
    let selectedPrinter
    if (printer.ip_address) {
      if (printer.port) {
        selectedPrinter = new JSPM.NetworkPrinter(printer.port, printer.ip_address)
      } else {
        selectedPrinter = new JSPM.NetworkPrinter(9100, printer.ip_address)
      }
    } else {
      selectedPrinter = new JSPM.InstalledPrinter(printer.printer_name)
    }

    cpj.clientPrinter = selectedPrinter

    // Set the ESC/POS commands
    cpj.binaryPrinterCommands = escposCommands

    EventBus.$emit('clear-receipt-data')

    // Send print job to printer!
    try {
      await cpj.sendToClient()
    } catch (err) {
      printerErrors('JSPM_not_running')
    }

    return true
  } else if (printerType === 'BIXOLON') {
    cutPaper()
    var strSubmit = getPosData()

    // printSeparator(options)

    let printerUrl
    const pName = printer.printer_name
    if (printer.ip_address && printer.ip_address !== '') {
      printerUrl = printer.ip_address
    }

    try {
      requestPrint(pName, strSubmit, viewResult, printerUrl)
    } catch (err) {
      alert('Greška pri reprintu računa, molimo pokušajte ponovno!')
      EventBus.$emit('clear-receipt-data')

      console.error(err)
    } finally {
      EventBus.$emit('clear-receipt-data')
    }

    return true
  }
}

const viewResult = (result) => {
  if (result === 'Cannot connect to server') {
    alert('Pisač je nedostupan.')
  }
}

const generateQrCode = (zki, jir, amt, options, date) => {
  if ((zki === '') && (jir === '')) return
  const year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()
  let hour = date.getHours()
  let minute = date.getMinutes()

  month = month < 10 ? '0' + month : month
  day = day < 10 ? '0' + day : day
  hour = hour < 10 ? '0' + hour : hour
  minute = minute < 10 ? '0' + minute : minute
  const formattedDate = `${year}${month}${day}_${hour}${minute}`

  amt *= 100
  amt = Math.round(amt * 100) / 100

  let rawUri = `https://porezna.gov.hr/rn/?datv=${formattedDate}&izn=${amt}`
  if (jir !== '') {
    rawUri = rawUri + `&jir=${jir}`
  }
  if (zki !== '') {
    rawUri = rawUri + `&zki=${zki}`
  }
  // 'https://porezna.gov.hr/rn/?datv=20220719_1521&izn=104%2C00&jir=d3cfcc2f-7f8b-444f-9622-1bd2c10b51e8'
  const uri = encodeURI(rawUri)

  if (options.document) {
    options.document.qrCode(uri, new options.escpos.BarcodeQROptions(options.escpos.QRLevel.L, 5))
  } else {
    printQRCode(uri, 0, 1, 4, 0)
  }
}

export const doPrintCartRecapitulation = async (data, printer) => {
  const printerType = printer?.library

  // Fix za staru konfiguraciju
  if (!['UNIVERSAL', 'NONE'].includes(printerType)) {
    if (!printer.font) printer.font = 0
    if (!printer.size) printer.size = 0
    if (!printer.width) printer.width = '80'
    if (!printer.line_length) printer.line_length = 48
    if (!printer.library) printer.library = 'BIXOLON'
  }

  let [escpos, doc, printerConfiguration] = [null, null, null]

  const cashRegisterResponse = await df.doc(`cash_registers/${state.getCashRegister().id}`).get()
  const cashRegister = cashRegisterResponse.data()

  printerConfiguration = printer

  if (printerType === 'UNIVERSAL') {
    escpos = window.Neodynamic.JSESCPOSBuilder
    doc = new escpos.Document()
  }

  const company = state.getCurrentCompany()
  const pointOfSale = state.getPointOfSale()
  const registerUser = state.getUser()
  const currency = 'EUR'
  const currencySymbol = printer.euro_symbol_type && printer.euro_symbol_type != null ? printer.euro_symbol_type : 'EUR'
  let total = 0

  data.items.forEach(item => {
    total += item.prices[currency].price / 100 * item.quantity
  })

  const companyAddress = `${company.address}, ${company.zip_code} ${company.city}`
  const pointOfSaleAddress = `${pointOfSale.address}, ${pointOfSale.zip_code} ${pointOfSale.city}`

  const options = {
    horizontal: 0,
    vertical: 0,
    bold: false,
    invert: false,
    underline: false,
    alignment: 0,
    font: printerConfiguration.font,
    printerConfiguration: printerConfiguration,
    document: doc,
    escpos: escpos
  }

  if (state.getPrinterLib === 'UNIVERSAL') {
    options.font = printerConfiguration.font
  } else {
    if (printerConfiguration.font === 'A') options.font = 0
    else if (printerConfiguration.font === 'B') options.font = 1
    else if (printerConfiguration.font === 'C') options.font = 2
  }

  function getOptions (option) {
    const modifiedOptions = JSON.parse(JSON.stringify(options))
    Object.keys(option).forEach(key => {
      modifiedOptions[key] = option[key]
    })
    modifiedOptions.document = options.document
    modifiedOptions.escpos = options.escpos
    modifiedOptions.printerConfiguration = options.printerConfiguration
    return modifiedOptions
  }
  // Begin print
  var date = new Date()

  printLine(company.name, getOptions({ alignment: 1 }))
  printLine(companyAddress + ', ' + company.country, getOptions({ alignment: 1 }))
  printLine(pointOfSale.name, getOptions({ alignment: 1 }))
  printLine(pointOfSaleAddress, getOptions({ alignment: 1 }))
  printLine(`OIB: ${company.oib}`, getOptions({ alignment: 1 }))

  printSeparator(options)

  const operater = registerUser && registerUser.name !== '' ? registerUser.name : ''
  if (cashRegister) {
    printTotals(`Operater: ${operater}`, 'Blagajna: ' + cashRegister.number, options)
  } else {
    printTotals(`Operater: ${operater}`, 'Blagajna: 1', options)
  }
  printTotals('Datum: ' + ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear(), 'Vrijeme: ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2), options)
  printSeparator(options)
  printLine('', options)

  printTotals('Artikl', 'Kol.', options)

  printSeparator(options)

  data.items.forEach(item => {
    printTotals(`${item.name.toUpperCase()}`.substring(0, printerConfiguration.line_length), formatCurrency(currency, item.quantity), options)
  })

  printSeparator(options)

  printTotals('Ukupno', formatCurrencyCustom(currencySymbol, total), getOptions({ bold: false, vertical: 0, horizontal: 1 }))

  printLine('\n'.repeat(8), options)

  if (printerType === 'UNIVERSAL') {
    doc.feed(1).cut()
    var escposCommands = options.document.generateUInt8Array()

    // create ClientPrintJob
    var cpj = new JSPM.ClientPrintJob()

    // Set Printer info
    let selectedPrinter
    if (printer.ip_address) {
      if (printer.port) {
        selectedPrinter = new JSPM.NetworkPrinter(printer.port, printer.ip_address)
      } else {
        selectedPrinter = new JSPM.NetworkPrinter(9100, printer.ip_address)
      }
    } else {
      selectedPrinter = new JSPM.InstalledPrinter(printer.printer_name)
    }

    cpj.clientPrinter = selectedPrinter

    // Set the ESC/POS commands
    cpj.binaryPrinterCommands = escposCommands

    // Send print job to printer!
    try {
      await cpj.sendToClient()
    } catch (err) {
      printerErrors('JSPM_not_running')
    }
  } else if (printerType === 'BIXOLON') {
    cutPaper()
    var strSubmit = getPosData()

    // printSeparator(options)

    let printerUrl
    const pName = printer.printer_name
    if (printer.ip_address && printer.ip_address !== '') {
      printerUrl = printer.ip_address
    }

    try {
      requestPrint(pName, strSubmit, viewResult, printerUrl)
    } catch (err) {
      alert('Greška pri reprintu računa, molimo pokušajte ponovno!')
      EventBus.$emit('clear-receipt-data')

      console.error(err)
    } finally {
      EventBus.$emit('clear-receipt-data')
    }

    return true
  }
}
